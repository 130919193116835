import React from "react";

import Obtpicvideo from "../../../components/subscribe/subscribeonebeautiful/obtpic-video";

export default function Subscribebeautifulbanner() {
  return (
    <section className="subscribe-banner-wrapper obt-subscription-block">
      <div className="container">
        <div className="row">
          <div class="col-12 pt-5 pb-4">
            <div className="row">
              <div className="col-md-8 pt-5">
                <h3 class="heading-wrapper px-4" style={{ textAlign: "left" }}>
                  Subscribe to One Beautiful{" "}
                  <span style={{ display: "block" }}>
                    {" "}
                    Thought after another{" "}
                  </span>
                </h3>

                <p className="font-weight500 theme-p-tag pb-5 mb-5 px-5">
                  {" "}
                  The inspirational wisdom of spiritual masters, one thought at
                  a time.
                </p>
              </div>

              <div class="col-md-3 text-center pt-5">
                <Obtpicvideo />
                <p className=" font-weight500 theme-p-tag"> Wisdom of the Masters</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
