const environment ="production";

const configuration = {
    production:{
        quoteUrl: "https://writenode.heartfulness.org/in//wp-json/v3/obt/single-today",
        siteUrl:  "https://south-africa-hfn-qa.web.app/"
    },
    staging:{
        quoteUrl: "https://writenode.heartfulness.org/in//wp-json/v3/obt/single-today",
        siteUrl:  "https://south-africa-hfn-qa.web.app/"
    }
   
}

const config = configuration[environment]

module.exports = config;