import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Subscribebreadcreumb from "../components/subscribe/subscribeonebeautiful/subscribe-breadcumb";

import Subscribebeautifulbanner from "../components/subscribe/subscribeonebeautiful/subscribe-banner";

import Onbeautifulcontentsec from "../components/subscribe/subscribeonebeautiful/one-beautiful-content";

// markup
const SubscribebeautifulPage = () => {
  return (
    <div>
      <Seo title="Subscribe to One Beautiful" />

      <Layout isSticky>
        <section>
          <Subscribebreadcreumb />
        </section>
        <section>
          <Subscribebeautifulbanner />
        </section>
        <section>
          <Onbeautifulcontentsec />
        </section>

      </Layout>
    </div>
  );
};

export default SubscribebeautifulPage;
