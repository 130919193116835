import React, { useState } from "react";

// import axios from "axios";

import moment from "moment";

import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Singleopt from "../subscribeonebeautiful/single-obt";

import obthumans from "../../../assets/images/subscribe/obt-humans.png";




import { Link } from "gatsby";

// import Singleopt from "../../../subscribeonebeautiful/single-obt";

const Onbeautifulcontentsec = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);

    const dataObj = {};
    const todayVal = moment(new Date()).format("MM-DD-YYYY h:mm:ss a");
    dataObj.submitteddate = todayVal;

    const formData = new FormData();
    formData.append("fname", data.firstname);
    formData.append("lname", data.lastname);
    formData.append("from", data.email);
    formData.append("Request_Type", "sub");
    formData.append("listname", "dailyref-html");
    formData.append("language_value", data.language);

    fetch("https://www.srcm.org/cgi-bin/newsletter.cgi", {
      body: formData,
      method: "post",
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.text())
      .then((data) => {
        setLoading(false);
        toast.success("Form submitted successfully");
        reset();
      })
      .catch((error) => {
        setLoading(false);
        toast.error("something went wrong");
      });
  };

  return (
    <section className="one-beautiful-content-wrapper pad60">
      <div className="container width-80-per pad60">
        <div className="row">
          <div class="col-md-6">
            <div class="daily-thought-sec py-5 p-3">
              <div class="custom-obt-padd">
                <h3 class="hfn-section-title font-medium mb-4">
                  One Beautiful Thought
                </h3>
               
                
                {/* <p clasName="obt-second-ptag">As your inner condition evolves, allow your manners and your lifestyle to evolve accordingly.</p> */}
                {/* <p className="obt-ptag-italic"></p> */}
                <div class="">
                  <Singleopt />
                </div>
                <br />
                {/* <a
                  class="btn mt-3 btn-blue btn-red"
                  href="/en/one-beautiful-thought-archive/EN/2022/04"
                >
                  Archive
                </a> */}

                <Link
                  to="https://www.heartfulnessmagazine.com/a-word-a-thought-a-question/" target="_blank" rel="noopener"
                  className="btn mt-3 btn-blue "
                >
               SUBSCRIBE
                </Link>

                <p></p>
              </div>
            </div>
          </div>

          <div className="col-md-6 form-shadow">
          <p className="theme-p-tag p-3">
              {" "}
              <img
              className="mg-bottom20 mt-3"
              src={obthumans}
              alt="Universal Prayer"
            />
              For any further information related to share your experience in heartfulness <br/>
              <b>Please contact </b> <br/>
              <a className="" href="mailto:devanr@volunteer.heartfulness.org ">devanr@volunteer.heartfulness.org</a> <br/> 
              <a className="" href="mailto: rsa.secretary@heartfulness.org "> rsa.secretary@heartfulness.org </a> <br/> 
              <a className="" href="mailto: za.secretary@srcm.org ">   za.secretary@srcm.org</a>
              </p>
            
            
             
          </div>
        </div>
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop={false}
          hideProgressBar
          closeOnClick
          rtl={false}
        />
      </div>
    </section>
  );
};

export default Onbeautifulcontentsec;