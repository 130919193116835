import React, { useState, useEffect } from "react";
import moment from "moment";
import config from "../../../assets/config";

import axios from "axios";

const Singleopt = () => {
  // Build Time Data Fetching
  const [footnote, setFootnote] = useState();
  const [content, setContent] = useState();
  const [displaydtstr, setDisplaydtstr] = useState();
  const todayVal = moment(new Date()).format("YYYY/MM/DD");
  
  useEffect(() => {
    axios
      .post(config.quoteUrl, {
        mode: "no-cors",
        crossDomain: true,
        Date: todayVal,
        headers: {
          Accept: "application/xml",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/xml; charset=utf-8",
        },
      })
      .then((response) => {
        const quoteData = response.data.data;
        if (quoteData) {
          setFootnote(quoteData.footnote);
          setContent(quoteData.content);
          setDisplaydtstr(
            moment(new Date()).format(
              "dddd,\xa0\xa0MMMM\xa0\xa0DD,\xa0\xa0YYYY"
            )
          );
        }

        return true;
      })
      .catch((error) => {
        return error.message;
      });
  });



return (
  

  <div>
  <p class="dr-display-date theme-p-tag">
  {displaydtstr}
                  </p>
                  <div class="dr-item-content">
                    <p>
                    {content}
                    </p>
                  </div>
                  <div class="obt-ptag-italic dr-footnote"> {footnote}  </div>
   
  
</div>
)

}
export default Singleopt